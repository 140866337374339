import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EventoService } from '../../../services/evento.service';
import { GoogleAnalytics } from '../../../shared/google-analytics';
import { MathHelper } from '../../../shared/math_helper';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public eventos: any[] = [];
    public eventosFiltro: any[] = [];

    @ViewChild('todos') todos: ElementRef;
    @ViewChild('realizados') realizados: ElementRef;
    // @ViewChild('suspendidos') suspendidos: ElementRef;
    @ViewChild('por_realizar') porRealizar: ElementRef;
    @ViewChild('ciclismo') ciclismo: ElementRef;
    @ViewChild('trail-running') trail_running: ElementRef;
    @ViewChild('running') running: ElementRef;
    // @ViewChild('escolar') escolar: ElementRef;
    // @ViewChild('tenis') tenis: ElementRef;

    public filtro = 'TODAS';

    query = '';

    constructor(
        private eventoService: EventoService,
        private mathHelper: MathHelper,
        private googleAnalytics: GoogleAnalytics,
        private activatedroute: ActivatedRoute,


    ) { }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('eventos', 'exitoso', '', 'click', 0);

        this.activatedroute.queryParams.subscribe(params => {
            if (params.query !== undefined) {
              this.query = params.query;
            }
          });

        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerEventos = this.eventoService.obtenerEventosVisibles(this.query);
        const obtenerEventosBikerace = this.eventoService.obtenerEventosBikeraceConLimiteVisibles(20);

        const petitions = forkJoin([obtenerEventos, obtenerEventosBikerace]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos = [];
                    res[0].forEach((element: any) => {
                        element.platform = 'riderticket';
                        this.eventos.push(element);
                    });
                    res[1].forEach((element: any) => {
                        element.platform = 'bikerace';
                        this.eventos.push(element);
                    });

                    this.eventosFiltro = this.eventos;
                    this.filtrarEventos('TODAS');

                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    filtrarEventos(estado: string) {
        this.filtro = estado;
        switch (estado) {
            case 'TODAS':
                this.eventosFiltro = this.eventos.filter((evento) => (evento.platform === 'riderticket' || evento.platform === 'bikerace') && (evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                // this.eventosFiltro = this.eventosFiltro.concat(this.eventos.filter((evento) => evento.platform === 'riderticket' && (evento.estado !== 'POR_REALIZAR' && evento.estado !== 'PROXIMAMENTE' && evento.estado !== 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1)));
                break;
            case 'POR_REALIZAR':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS');
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;
            case 'REALIZADO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            case 'SUSPENDIDO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            case 'bikerace':
                this.eventosFiltro = this.eventos.filter((evento) => evento.platform === 'bikerace' && (evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                this.eventosFiltro = this.eventosFiltro.concat(this.eventos.filter((evento) => evento.platform === 'bikerace' && (evento.estado !== 'POR_REALIZAR' && evento.estado !== 'PROXIMAMENTE' && evento.estado !== 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1)));
                break;
            default:
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;

        }
    }




}
