import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuspiciadorService } from 'src/app/services/auspiciador.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { PublicidadService } from 'src/app/services/publicidad.service';
import Swal from 'sweetalert2';
import { environment } from "../../../../environments/environment";
import { EventoService } from '../../../services/evento.service';

@Component({
    selector: 'app-home-two',
    templateUrl: './home-two.component.html',
    styleUrls: ['./home-two.component.scss']
})
export class HomeTwoComponent implements OnInit {
    private compDestroy: Subject<boolean> = new Subject();

    public loading = false;
    public filtro = 'TODAS';
    public eventosFiltro: any[] = [];

    @ViewChild('todos') todos: ElementRef;
    @ViewChild('realizados') realizados: ElementRef;
    // @ViewChild('suspendidos') suspendidos: ElementRef;
    @ViewChild('por_realizar') porRealizar: ElementRef;
    @ViewChild('ciclismo') ciclismo: ElementRef;
    @ViewChild('trail-running') trail_running: ElementRef;
    @ViewChild('motociclismo') motociclismo: ElementRef;
    // @ViewChild('escolar') escolar: ElementRef;
    // @ViewChild('tenis') tenis: ElementRef;

    public eventos: any[] = [];
    public clientes: any[] = [];
    public auspiciadores: any[] = [];
    public afiches: any[] = [];


    nombre = '';
    lugar = '';

    constructor(
        private eventoService: EventoService,
        private clienteService: ClienteService,
        private auspiciadorService: AuspiciadorService,
        private publicidadService: PublicidadService,
        private router: Router,


    ) { }

    ngOnInit(): void {
        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerEventos = this.eventoService.obtenerEventosConLimiteVisibles(20);
        const obtenerEventosBikerace = this.eventoService.obtenerEventosBikeraceConLimiteVisibles(20);
        const obtenerClientesVisibles = this.clienteService.obtenerClientesVisibles();
        const obtenerAuspiciadoresVisibles = this.auspiciadorService.obtenerAuspiciadoresVisibles();
        const obtenerAfiche = this.publicidadService.obtenerAfichePorEstado('DISPONIBLE');

        const petitions = forkJoin([obtenerEventos, obtenerEventosBikerace, obtenerClientesVisibles, obtenerAuspiciadoresVisibles, obtenerAfiche]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any) => {
                    this.eventos = [];
                    res[0].forEach((element: any) => {
                        element.platform = 'riderticket';
                        this.eventos.push(element);
                    });
                    res[1].forEach((element: any) => {
                        element.platform = 'bikerace';
                        this.eventos.push(element);
                    });
                    this.clientes = res[2];
                    this.auspiciadores = res[3];
                    this.afiches = [];
                    this.afiches = res[4];
                    if (this.afiches.length > 0) {
                        Swal.fire({
                            // padding: 20,
                            title: `${this.afiches[0].titulo}`,
                            // text: 'Modal with a custom image.',
                            // imageUrl: `${this.afiches[0].url_imagen}`,
                            // imageWidth: '20%',
                            // imageHeight: '40%',
                            html: `<img src="${this.afiches[0].url_imagen}">`,
                            heightAuto: false,
                            imageAlt: 'Afiche bikerace',
                            showCancelButton: true,
                            cancelButtonText: 'Cerrar',
                            confirmButtonText: `${this.afiches[0].texto_boton}`,
                            reverseButtons: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.open(`${this.afiches[0].url_redireccion}`, '_blank');
                            }
                        });
                    }

                    this.eventosFiltro = this.eventos;
                    this.filtrarEventos('TODAS');

                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    filtrarEventos(estado: string) {
        this.filtro = estado;
        switch (estado) {
            case 'TODAS':
                this.eventosFiltro = this.eventos.filter((evento) => (evento.platform === 'riderticket' || evento.platform === 'bikerace') && (evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                // this.eventosFiltro = this.eventosFiltro.concat(this.eventos.filter((evento) => evento.platform === 'riderticket' && (evento.estado !== 'POR_REALIZAR' && evento.estado !== 'PROXIMAMENTE' && evento.estado !== 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1)));
                break;
            case 'POR_REALIZAR':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS');
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;
            case 'REALIZADO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            case 'SUSPENDIDO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            case 'bikerace':
                this.eventosFiltro = this.eventos.filter((evento) => evento.platform === 'bikerace' && (evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                this.eventosFiltro = this.eventosFiltro.concat(this.eventos.filter((evento) => evento.platform === 'bikerace' && (evento.estado !== 'POR_REALIZAR' && evento.estado !== 'PROXIMAMENTE' && evento.estado !== 'INSCRIPCIONES_CERRADAS')).sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1)));
                break;
            default:
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;

        }
    }

    generarUrlBikerace(path: string) {
        return `${environment.urlBikeraceWeb}${path}`;
    }

    buscar() {
        let query = `${this.nombre} ${this.lugar}`;
        if (query[0] === ' ') {
            query = query.slice(1);
        }

        if (query[query.length - 1] === ' ') {
            query = query.substring(0, query.length - 1);

        }

        this.router.navigate(['/eventos'], { queryParams: { query: `${query}` } });

    }
}
